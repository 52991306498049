import { Injectable, makeStateKey, TransferState, Inject, PLATFORM_ID } from '@angular/core';
import Fuse from 'fuse.js';
import { BehaviorSubject } from 'rxjs';
import { firstValueFrom, Observable } from 'rxjs';
import webpage from './../assets/webpage.json';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { PreloadService } from './preload.service';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
declare var require: any;

@Injectable({
  providedIn: 'root'
})
export class WebpageService {

  webpage:any = webpage;
  items: any;
  //items = items;
  myFuseOptions = {
    caseSensitive: false,
    keys: ["n", "c"],
    shouldSort: true,
  };
  myFuse: any;
  searchResults = new BehaviorSubject<any>({items: [], searchbarInputLength: 0});
  isBrowser: any;
  clearSearchSubject = new BehaviorSubject<boolean>(false);
  rootCategoriesSubject = new BehaviorSubject<any>([]);
  localeSubject = new BehaviorSubject<any>(null);
  rootCategories:any;
  locale: any;
  showSearchBar: boolean = false;
  FIREBASE_WEBPANEL_FUNCTIONS_URL = "https://us-east1-webpanel-admin.cloudfunctions.net/myFunctions/"


  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: Object,
    private transferState: TransferState,
    private title: Title,
    private router: Router,
    private preloadService: PreloadService,
    private meta: Meta) {

    // Create the Fuse index
    this.isBrowser = isPlatformBrowser(platformId);
  }

  triggerClear() { this.clearSearchSubject.next(true); }

  clearCompleted() { this.clearSearchSubject.next(false); }

  search(searchbarInput:any) {
    //console.log('SearchbarInput: ', searchbarInput);
    const items = this.myFuse.search(searchbarInput).map((i:any) => i.item);
    this.searchResults.next({items, searchbarInputLength: items.length });
  }

  getDataFromJSONFile(folder:any, slug:any) { return firstValueFrom(this.http.get(`/assets/${folder}/${slug}.json`)); }

  getWebpageFileJSON() { return firstValueFrom(this.http.get(`/assets/webpage.json`)); }


  initializeSearchbar(locale: any){
    if(this.isBrowser) {
      if(!this.myFuse) {

        this.http.get(`/assets/searchbars/${locale}-searchbar.json`).subscribe((data) => {
          this.items = data;
          const myFuseIndex = Fuse.createIndex(this.myFuseOptions.keys, this.items);
          this.myFuse = new Fuse(this.items, this.myFuseOptions, myFuseIndex);
        });
      }
    }
    else {
     /* let fs = require('fs');
      let data = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/searchbars/${locale}-searchbar.json`, 'utf8'));
      this.items = data;
      let key = `${locale}-searchbar`;
      const transferStateKey = makeStateKey<any>(key);
      this.transferState.set(transferStateKey, data);*/
    }
  }

  transformFilname(input:any) {

    const image = input.replace(/(\.(jpg|webp))$/, '-1x$1');
    return `assets/images/${image}`;

   /* const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return filename; // In case there's no extension
    }
    const name = filename.substring(0, dotIndex);
    const extension = filename.substring(dotIndex);
    return `${name}-1x${extension}`;*/
  }

  sendMessageViaContactForm(data: any) {
    const url = this.FIREBASE_WEBPANEL_FUNCTIONS_URL + 'sendMessageViaContactForm';
    return this.http.post(url, data);
  }

  clearSearchbarInput() { this.searchResults.next({items: [], searchbarInputLength: 0 }); }

  async openSearchbarItem(item: any, locale:any) {
    const path = `/${locale}/coloring-pages/${item.s}`;
    this.clearSearchbarInput();
    this.router.navigate([path]);
    this.triggerClear();
  }

  getSearchbarData (searchbarInput: any) { return this.myFuse.search(searchbarInput, {limit: 12}).map((i:any) => i.item); }

  async getItem(slug:any, folder:any) {
    if(this.isBrowser) {
      if (this.transferState.hasKey(slug)) {
        let item = this.transferState.get(slug, null);
        if (folder === 'roots') { this.rootCategoriesSubject.next(item);}
        //this.transferState.remove(slug); // Optional: remove the key after retrieving it
        return item;
      } else {
        let item = await this.getDataFromJSONFile(folder, slug);
        if (folder === 'roots') { this.rootCategoriesSubject.next(item);}
        //console.log('Item: ', item);
        return item;
      }
    }
    else {
      let fs = require('fs');
      let item = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/${folder}/${slug}.json`, 'utf8'));
      const transferStateKey = makeStateKey<any>(slug);
      this.transferState.set(transferStateKey, item);
      return item;
    }
  }

  async getWebpageData(locale:any) {
    const slug:any = `webpage-${locale}`;
    //console.log('Slug: ', slug);
    if (this.isBrowser) {
      //console.log('IsBrowser: ', this.isBrowser);
      if (this.transferState.hasKey(slug)) {
        let webpage:any = this.transferState.get(slug, null);
        return webpage;
      } else {

        let webpage:any = await this.getWebpageFileJSON();
        webpage = {...webpage, ...webpage.locales.find((item:any) => { return item.locale === locale; }) };
        //console.log(webpage);
        return webpage;
      }
    }
    else {
      let fs = require('fs');
      let webpage:any = JSON.parse(fs.readFileSync(`dist/functions/browser/assets/webpage.json`, 'utf8'));
      webpage = {...webpage, ...webpage.locales.find((item:any) => { return item.locale === locale; }) };
      const transferStateKey = makeStateKey<any>(slug);
      this.transferState.set(transferStateKey, webpage);
      return webpage;
    }
  }

  getRootCategories() { return this.rootCategoriesSubject.asObservable().pipe();  }

  getLocale() { return this.localeSubject.asObservable().pipe();  }

  setLocale(locale:any) { this.localeSubject.next(locale); }

  getSearchbarResults() { return this.searchResults.asObservable().pipe(); }

  slugify (str:any) { return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '')};

  getShortnameForArticle(slug:any) {
    let splitSlug = slug.split('-');
    return splitSlug.join(' ');
  }

  transformFilename(input: string): string {
    const image = input.replace(/(\.(jpg|webp))$/, '-1x$1');
    return `assets/images/${image}`;
  }

  setMetaTags(item:any) {
    //console.log('Setting meta tags...');
    const image1x = this.transformFilename(item.image);
    this.title.setTitle(item.name);
    const canonical = `https://${this.webpage.name}/${item.path}`;
    this.preloadService.setCanonicalURL(canonical);

    this.meta.updateTag({name:'description', content: item.metaDescription});
    this.meta.updateTag({name:'keywords', content: item.keywords});
    this.meta.updateTag({name:'google-site-verification', content: 'YrqDd7lmbOc1eahLxGsYasq3tqXMF0mgAOGcfql_k-c' });
    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.name});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.name}`});
    this.meta.updateTag({name:'og:title', content: item.name});
    this.meta.updateTag({name:'og:description', content: item.metaDescription});
    this.meta.updateTag({name:'og:image', content: `https://${this.webpage.name}/assets/images/${item.image}`}); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.name}` });
    this.meta.updateTag({name:'twitter:title', content: item.name });
    this.meta.updateTag({name:'twitter:description', content: item.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `https://${this.webpage.name}/assets/images/${image1x}`});
  }
}