import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PreloadService {
  private renderer: Renderer2;
  preloadedImages:any[] = [];

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  preloadImages(urls: any): void {
    this.clearPreloadedImages();
    for (let url of urls) {
      let link:any = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'preload');
      this.renderer.setAttribute(link, 'as', 'image');
      this.renderer.setAttribute(link, 'href', url);
      this.renderer.appendChild(this.document.head, link);

      // Keep track of the created link element
      this.preloadedImages.push(link);
    }
  }

  private clearPreloadedImages(): void {
    for (const link of this.preloadedImages) {
      this.renderer.removeChild(this.document.head, link);
    }
    this.preloadedImages = [];
  }

  setCanonicalURL(url: string) {
    let link: any = this.document.querySelector('link[rel="canonical"]');
    if (link) {
      this.renderer.setAttribute(link, 'href', url);
    } else {
        link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'rel', 'canonical');
        this.renderer.setAttribute(link, 'href', url);
        this.renderer.appendChild(this.document.head, link);
    }
  }

}