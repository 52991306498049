import { Component, Input } from '@angular/core';
import { TextService } from '../../services/text.service';
import { WebpageService } from '../../services/web.service';

@Component({
  selector: 'related-items',
  templateUrl: './related-items.component.html',
  styleUrls: ['./related-items.component.scss'],
  animations: [ ]
})
export class RelatedItemsComponent {

  @Input() relatedItems: any;
  text: any = {};
  locale$:any;
  locale: any;

  constructor(public textService: TextService, private webpageService: WebpageService) {

    this.translateTexts();
  }

  translateTexts() {
    this.text.subheading = this.textService.getText('subheading', 'relatedItems');
    this.text.ariaLabel = this.textService.getText('ariaLabel', 'relatedItems');
  }

  ngOnDestroy() { }



}
