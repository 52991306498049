/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, OnInit, Input, Inject, ViewChild, Renderer2, ElementRef, ChangeDetectorRef, PLATFORM_ID } from '@angular/core';
import { ModalController, Platform, NavParams } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';


@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.modal.html',
  styleUrls: ['./preview-image.modal.scss'],
})
export class PreviewImageModal {


  @Input() image: any;

  @ViewChild('myCanvas', { static: false }) private myCanvas: ElementRef = {} as ElementRef;
  isColoring = false;
  canvas: any;
  lastX: any;
  lastY: any;
  context: any;
  mouseDown = false;
  IMAGE_URL = 'assets/images/';
  selectedImage: any;


  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public platform: Platform,
    private modalController: ModalController,
    navParams: NavParams,
    public renderer: Renderer2) {

    this.image = navParams.get('image');
  }


  ngAfterViewInit(): void { this.setupCanvas(); }

  setupCanvas(): void {
    this.changeDetectorRef.detectChanges();
    this.canvas = this.myCanvas?.nativeElement;
    this.renderer.setAttribute(this.canvas, 'width', this.platform.width() + '');
    this.renderer.setAttribute(this.canvas, 'height', this.platform.height() + '');
    this.context = this.canvas.getContext('2d');
    let background = new Image();
    background.src = this.image;
    background.setAttribute('crossOrigin', '');
    setTimeout(() => {
      this.context.drawImage(background, 0,0, this.platform.width(), this.platform.height());
    }, 1000);
  }

  getPixelData(x:any, y:any ) { return this.context.getImageData(x, y, 1, 1).data; }

  handleTouchStart(ev: any) {
    this.lastX = ev.touches[0].pageX;
    this.lastY = ev.touches[0].pageY;
    this.mouseDown = true; // Start drawing
  }

  handleTouchMove(ev: any) {
    if (!this.mouseDown) return;
    this.draw(ev.touches[0].pageX, ev.touches[0].pageY);
  }

  handleTouchEnd(ev: any) {
    this.mouseDown = false; // Stop drawing
  }

  handleMouseDown(ev: any) {
    this.lastX = ev.pageX;
    this.lastY = ev.pageY;
    this.mouseDown = true; // Start drawing
  }

  handleMouseMove(ev: any) {
    if (!this.mouseDown) return;
    this.draw(ev.pageX, ev.pageY);
  }

  handleMouseUp(ev: any) {
    this.mouseDown = false; // Stop drawing
  }

  private draw(x: number, y: number) {
   /* this.context.beginPath();
    this.context.moveTo(this.lastX, this.lastY);
    this.context.lineTo(x, y);
    this.context.strokeStyle = '#00FFFF';
    this.context.lineWidth = 2;
    this.context.stroke();
    this.lastX = x;
    this.lastY = y;*/

    const pixel = this.getPixelData(x, y);
    // Check if the pixel is not black (allowing a small buffer for anti-aliasing)
    if (!(pixel[0] < 10 && pixel[1] < 10 && pixel[2] < 10)) {
        this.context.beginPath();
        this.context.strokeStyle = '#00FFFF';
        this.context.arc(x, y, 2.5, 0, Math.PI * 2);
        this.context.fill();
    }

  }

  dismissModal() {
    this.modalController.dismiss();
  }

  /*
  color() {
    this.isColoring = true;
    this.changeDetectorRef.detectChanges();
    this.canvas = this.myCanvas?.nativeElement;
    console.log(this.canvas);
    this.renderer.setAttribute(this.canvas, 'width', this.platform.width() + '');
    this.renderer.setAttribute(this.canvas, 'height', this.platform.height() + '');
    this.context = this.canvas.getContext('2d');
    console.log(this.context);
    let background = new Image();
    background.src = this.IMAGE_URL + this.selectedImage;
    setTimeout(() => {
      this.context.drawImage(background, 0,0, this.platform.width(), this.platform.height());
    }, 1000);
  }


  handleTouchStart(ev:any) {
    console.log(ev);
    this.lastX = ev.touches[0].pageX;
    this.lastY = ev.touches[0].pageY;
  }

  handleTouchMove(ev:any) {
    console.log(ev);
    let currentX = ev.touches[0].pageX;
    let currentY = ev.touches[0].pageY;

    this.context.beginPath();
    this.context.moveTo(this.lastX, this.lastY);
    this.context.lineTo(currentX, currentY);
    this.context.closePath();
    this.context.strokeStyle = '#000000';
    this.context.lineWidth = 5;
    this.context.stroke();

    this.lastX = currentX;
    this.lastY = currentY;
  }

  handleTouchEnd(ev:any) {
    console.log(ev);
  }

  handleMouseDown(ev:any) {
    console.log(ev);
    this.mouseDown = true;
    this.lastX = ev.pageX;
    this.lastY = ev.pageY;
  }

  handleMouseMove(ev:any) {
    console.log(ev);
    if (this.mouseDown) {

    this.context.beginPath();
    this.context.moveTo(this.lastX, this.lastY);
    this.context.lineTo(ev.pageX, ev.pageY);
    this.context.closePath();
    this.context.strokeStyle = '#000000';
    this.context.lineWidth = 5;
    this.context.stroke();
    }

    this.lastX = ev.pageX;
    this.lastY = ev.pageY;
  }

  handleMouseUp(ev:any) {
    console.log(ev);
    this.mouseDown = false;
  }

  */

}
