import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { TextService } from '../../services/text.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Platform } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  messageSuccess: any;
  messageError: any;
  locale: any;
  text:any = {};
  contactForm: FormGroup;

  constructor(
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private webpageService: WebpageService,
    public platform: Platform,
    public textService: TextService,
    private formBuilder: FormBuilder
  ) {

    this.route.url.subscribe(async segments => {
      this.locale = segments[0].path;
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.webpageService.setLocale(this.locale);
      this.translateTexts();
      this.setMetaTags();
      this.webpageService.initializeSearchbar(this.locale);
    })

    this.contactForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
    });

  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilname(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  async onSubmitContactForm() {
    if (this.contactForm.valid) {
      this.messageSuccess = this.text.messageSuccess;
      const response:any = await firstValueFrom(this.webpageService.sendMessageViaContactForm(this.contactForm.value));
      if (response.status === 'OK') {
        this.contactForm.reset();
      }
      else {
        this.messageError = this.text.messageError;
      }
    }
  }

  translateTexts() {
    this.text.messageError = this.textService.getText('messageError', 'contact');
    this.text.messageSuccess = this.textService.getText('messageSuccess', 'contact');
    this.text.pageTitle = this.textService.getText('pageTitle', 'contact');
    this.text.submit = this.textService.getText('submit', 'contact');
    this.text.messageLabel = this.textService.getText('messageLabel', 'contact');
    this.text.messagePlaceholder = this.textService.getText('messagePlaceholder', 'contact');
    this.text.nameLabel = this.textService.getText('nameLabel', 'contact');
    this.text.namePlaceholder = this.textService.getText('namePlaceholder', 'contact');
    this.text.emailLabel = this.textService.getText('emailLabel', 'contact');
    this.text.emailPlaceholder = this.textService.getText('emailPlaceholder', 'contact');
  }

  setMetaTags() {
    this.title.setTitle(this.text.pageTitle);
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
  }


}
