import { Component, Input} from '@angular/core';
import { TextService } from '../../services/text.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [ ]
})
export class FooterComponent {

  text:any = {};
  routes:any = {};

  constructor(public textService: TextService) {

      this.routes.contact = this.textService.getRoute('contact');
      this.routes.privacyPolicy = this.textService.getRoute('privacyPolicy');
      this.text.contact = this.textService.getText('contact', 'footer');
      this.text.label = this.textService.getText('label', 'footer');
      this.text.privacyPolicy = this.textService.getText('privacyPolicy', 'footer');

  }




}
