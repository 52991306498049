import { Component, HostListener} from '@angular/core';
import { InfiniteScrollCustomEvent} from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { Subscription } from 'rxjs';
import { RouteReuseStrategy, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {

  webpage: any;
  searchbarResults$: Subscription | undefined;
  categories:any;
  items: any[] = [];
  articles: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  currentItem: any;
  locale = 'en';
  maxCategories:any;
  itemPath: any;
  articlePath: any;
  subheadingA:any;
  subheadingB:any;
  subheadingC:any;
  buttonTextA: any;
  buttonTextB: any;
  buttonTextC: any;
  showCategories = false;
  showArticles = false;
  itemsPerBatch = 16;
  allDataLoaded = false;
  isScrollEventDisabled = false;

  constructor(
    private routeReuseStrategy: RouteReuseStrategy,
    private title: Title,
    private meta: Meta,
    private route: ActivatedRoute,
    private preloadService: PreloadService,
    private webpageService: WebpageService,
  ) {

    this.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.url.subscribe(async segments => {
      this.webpage = await this.webpageService.getWebpageData(this.locale);
      this.webpageService.setLocale(this.locale);
      this.setMetaTagsForMainPage();
      this.webpageService.initializeSearchbar(this.locale);
      let results = await this.webpageService.getItem(`${this.locale}-starter`, `starters`);
      this.items = results.items;
      // console.log(this.items);
      this.items.forEach((item: any) => {
        item.img1x = this.webpageService.transformFilname(item.img);
      });
    })
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilname(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  async loadAllData() {
    let mainpage = await this.webpageService.getItem(`${this.locale}-mainpage`, `mainpages`);
    const data = mainpage.items;
    this.items = this.items.concat(data);
      this.items.forEach((item: any) => {
        item.img1x = this.webpageService.transformFilname(item.img);
      });
    this.allDataLoaded = true;
  }

  loadMoreData(ev:any) {
    this.itemsPerBatch = this.itemsPerBatch + 16;
    if (!this.allDataLoaded) {
      this.loadAllData();
    }
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
      if (this.items.length < this.itemsPerBatch) {
        ev.target.disabled = true;
      }
    }, 500);
  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item, this.locale); }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  handleScroll(ev:any) {
    if (!this.allDataLoaded) {
      this.loadAllData();
    }

  }

  setMetaTagsForMainPage() {
    this.title.setTitle(this.webpage.metaTitle);

      const canonical = `https://${this.webpage.name}`;
      this.preloadService.setCanonicalURL(canonical);
    this.meta.updateTag({name:'google-site-verification', content: 'YrqDd7lmbOc1eahLxGsYasq3tqXMF0mgAOGcfql_k-c' });
    this.meta.updateTag({name:'description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'keywords', content: this.webpage.metaKeywords});
    //Facebook
    this.meta.updateTag({name:'og:type', content: 'website'});
    this.meta.updateTag({name:'og:site_name', content: this.webpage.metaWebpage});
    this.meta.updateTag({name:'og:url', content: `https://${this.webpage.metaWebpage}` });
    this.meta.updateTag({name:'og:title', content: this.webpage.metaTitle });
    this.meta.updateTag({name:'og:description', content: this.webpage.metaDescription });
    this.meta.updateTag({name:'og:image', content: `${this.webpage.metaImage}` }); // Recommend 1200×628

    this.meta.updateTag({name:'twitter:card', content: "summary_large_image"});
    this.meta.updateTag({name:'twitter:url', content: `https://${this.webpage.metaWebpage}`});
    this.meta.updateTag({name:'twitter:title', content: this.webpage.metaTitle});
    this.meta.updateTag({name:'twitter:description', content: this.webpage.metaDescription});
    this.meta.updateTag({name:'twitter:image', content: `${this.webpage.metaImage300}`  });
  }


}
