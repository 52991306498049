<footer>
  <div class="container">
    <nav class="footer-nav" [attr.aria-label]="text?.label">
      <ul>
        <li><a routerLink="{{routes.contact}}">{{text?.contact}}</a></li>
        <li><a routerLink="{{routes.privacyPolicy}}">{{text?.privacyPolicy}}</a></li>
      </ul>
    </nav>
    <span class="copyrights">Copyright © 2024 supremecoloring.com. All rights reserved.</span>
  </div>
</footer>