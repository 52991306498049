import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  constructor() { }

  getText(key:any, webpage:any) {
    const text:any = {
        contact: {
          pageTitle: "Contact Form",
          messageError: "Error! Something went wrong!",
          messageSuccess: "Thank you! Your message has been sent successfully. We will get back to you soon.",
          submit: "Submit",
          messageLabel: "Message",
          messagePlaceholder: "Your message...",
          nameLabel: "Name",
          namePlaceholder: "Name...",
          emailLabel: "Email",
          emailPlaceholder: "Enter email..."
        },
        footer: {
          label: "Footer navigation",
          contact: 'Contact',
          privacyPolicy: 'Privacy policy'
        },
        privacyPolicy: {
          pageTitle: "Privacy Policy",
          contents: "Contents",
          introduction: "Welcome to SuperemeColoring.com, a hub for creativity and relaxation through coloring. We value your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and safeguard your data when you visit our website. By accessing and using SuperemeColoring.com, you agree to the terms outlined in this policy.",
          sections: [
              {
                subheadingRef: "subheadingRef1",
                subheading: "Information Collection and Use",
                section: "We collect data to enhance your experience on our website. The primary tool we use for data collection is Google Analytics. This service helps us understand how visitors use our site, such as which pages are visited the most and how users navigate through our website. The types of data collected by Google Analytics include: - The type of browser you use - The time you spend on the website - The pages you visit - The referring site or navigation path to our site. This information is used to improve website content and navigation, ensuring a better user experience. We do not use this data for any identification purposes nor do we share personal information collected from our site with third parties, except as required by law."
              },
              {
                subheadingRef: "subheadingRef2",
                subheading: "Data Protection",
                section: "We implement a variety of security measures to maintain the safety of your personal information when you visit our website. Our efforts include encryption, access controls, and security protocols designed to protect data on our network. Although we strive to protect your personal data, no security system is impenetrable; therefore, we cannot guarantee the absolute security of our databases, nor can we guarantee that the information you supply will not be intercepted while being transmitted to us over the Internet."
              },
              {
                subheadingRef: "subheadingRef3",
                subheading: "Your Data Rights",
                section: "You have certain rights concerning the personal data we hold about you. These may include the right to request access to the data, to request corrections of any inaccuracies, or to request deletion of the data. To exercise any of these rights, please contact us directly through our contact information available on our website."
              },
              {
                subheadingRef: "subheadingRef4",
                subheading: "Changes to Our Privacy Policy",
                section: "We may update this Privacy Policy occasionally to reflect changes in our practices and service offerings. If we modify our Privacy Policy, we will update the 'Last Modified' date at the top of the policy and encourage you to review it frequently. Changes to our Privacy Policy become effective once they are posted on this page."
              }
            ],
        privacyPolicyPopup: {
          slogan: "This website uses cookies to provide the best experience on our website."
        },
        relatedItems: {
          subheading: 'YOU MAY ALSO LIKE',
          ariaLabel: 'Related item: '
        },
        topheader: {
          websiteName: 'GOODTATTOODESIGNS',
          addImage: 'TATTOO IMAGE',
          placeholderText: 'Search...',
          arialabel: 'Go to homepage',
          ariaButtonSearch: 'Search button',
          ariaButtonCloseSearch: 'Close search button',
          ariaButtonCloseMenu: 'Close menu button',
          ariaButtonMenu: 'Menu button',
        }
      },
      privacyPolicyPopup: {
        slogan: "This website uses cookies to provide the best experience on our website."
      },
      relatedItems: {
        subheading: 'YOU MAY ALSO LIKE',
        ariaLabel: 'Related item: '
      },
      topheader: {
        placeholderText: 'Search...',
        arialabel: 'Go to homepage',
        ariaButtonSearch: 'Search button',
        ariaButtonCloseSearch: 'Close search button',
        ariaButtonCloseMenu: 'Close menu button',
        ariaButtonMenu: 'Menu button',
      }
    }
    if (text[webpage]) {
      return text[webpage][key]
    }
  }

  getRoute(key:any) {

    const routes:any = {
        contact: 'contact-us',
        privacyPolicy: 'privacy-policy'
    }
    return '/' + routes[key];
  }


}