
<ion-row class="header-space"></ion-row>
<ion-content>
  <ion-grid>
    <ion-row style="height: 100%">
      <ion-col class="ads" size="1"> </ion-col>
      <ion-col size-md="12" size-lg="10" class="main-column">

        <ion-list *ngIf="searchbarInputLength === 0" role="list">
          <div class="h1-container" role="listitem"><h1 class="name extra-margin-top">{{subheadingA}}</h1></div>
          <div class="home-grid">
            <div class="home-item" role="listitem" *ngFor="let item of items| slice:0:itemsPerBatch; let i = index">
                <div [ngClass]="{'item-image-container-rectangle': i % 2 === 0 }" class="item-image-container">
                  <ion-skeleton-text aria-hidden="true" [animated]="true" [ngClass]="{'hidden': item.loaded}" ></ion-skeleton-text>
                  <a [routerLink]="'/en/coloring-pages/' + item.slug" [state]="{data: item}" [attr.aria-label]="'Open ' + item?.name" role="button" tabindex="0" >
                    <ion-img alt="{{item?.imageAlt}}" src="{{item?.img1x}}" (ionImgDidLoad)="item.loaded = true"></ion-img>
                  </a>
                </div>
            </div>

            <ion-infinite-scroll threshold="10%" (ionInfinite)="loadMoreData($event)">
              <ion-infinite-scroll-content loadingSpinner="bubbles"></ion-infinite-scroll-content>
            </ion-infinite-scroll>

          </div>
          <app-footer *ngIf="showCategories"></app-footer>
        </ion-list>

        <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>

      </ion-col>
      <ion-col class="ads" size="1"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
