<ion-list [ngClass]="listClass" *ngIf="searchbarInputLength > 0" class="ion-no-padding" role="list">
    <ion-row class="ion-no-padding">
      <ion-col class="ion-no-padding" role="listitem" *ngFor="let searchbarItem of searchbarItems | slice:0:12" size-xs="6" size-sm="6" size-md="4" size-lg="4">
        <div class="item-container default-zoom-color zoom-effect" (click)="openSearchbarItem(searchbarItem)">
          <div class="item-image-container">
            <ion-skeleton-text *ngIf="!searchbarItem.loaded" [animated]="true"></ion-skeleton-text>
            <ion-img (ionImgDidLoad)="searchbarItem.loaded = true" alt="{{searchbarItem.n}}" src="{{searchbarItem?.img1x}}" [ngClass]="{'fadeIn': searchbarItem.loaded, 'article': searchbarItem.t === 'A'}"></ion-img>
          </div>
          <!--<span class="item-name">{{searchbarItem.n}}</span>-->
        </div>
      </ion-col>
    </ion-row>
  </ion-list>