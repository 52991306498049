
<ion-grid>
  <ion-row>
    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="8" offset-lg="2" class="ion-no-padding">
      <div class="top-header">
        <nav class="desktop-menu">
          <a [routerLink]="homePath"  [attr.aria-label]="text.arialabel" role="button" tabindex="0">
            <img class="desktop-logo" alt="Logo" src="assets/logo-100.jpg" />
          </a>
          <a [routerLink]="homePath"  [attr.aria-label]="text.arialabel" role="button" tabindex="0">
            <div>SUPREME COLORING</div>
          </a>
        </nav>
      </div>
      <ion-toolbar>
        <ion-searchbar
        [debounce]="500"
        placeholder="{{text.placeholderText}}"
        (ionInput)="searchItem($event)"
        (ionClear)="clearSearchbar()"
        value="{{searchbarInput}}">
        </ion-searchbar>
      </ion-toolbar>
    </ion-col>
  </ion-row>
</ion-grid>