import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HomePage } from '../pages/home/home.page';
import { ItemPage } from '../pages/item/item.page';
import { PrivacyPolicyPage } from 'src/pages/privacy-policy/privacy-policy.page';
import { ContactPage } from 'src/pages/contact/contact.page';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'; // usuniete IonicRouteStrategy
import { FooterComponent } from 'src/components/footer/footer.component';
import { PrivacyPolicyComponent } from 'src/components/privacy-policy/privacy-policy.component';
import { RelatedItemsComponent } from 'src/components/related-items/related-items.component';
import { SearchbarItemsListComponent } from 'src/components/searchbar-items-list/searchbar-items-list.component';
import { ShareButtonsComponent } from 'src/components/share-buttons/share-buttons.component';
import { TopHeaderComponent } from 'src/components/top-header/top-header.component';
import { HttpClientModule } from '@angular/common/http';
import { PreviewImageModal } from 'src/components/preview-image/preview-image.modal';

@NgModule({
  declarations: [
    AppComponent,
    ContactPage,
    HomePage,
    ItemPage,
    FooterComponent,
    PreviewImageModal,
    PrivacyPolicyComponent,
    PrivacyPolicyPage,
    RelatedItemsComponent,
    SearchbarItemsListComponent,
    ShareButtonsComponent,
    TopHeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot({animated: true}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [ ],
  //providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }], // dodane
  bootstrap: [AppComponent]
})
export class AppModule { }
