<ion-row class="header-space"></ion-row>

  <ion-content>
    <ion-row>
      <ion-col class="ads" size="2"></ion-col>
      <ion-col size-md="12" size-lg="8" class="ion-no-padding">
        <ion-list role="list" *ngIf="searchbarInputLength === 0" style="padding-top: 0">
          <div class="h1-container" role="listitem"><h1 class="name">{{item?.name}}</h1></div>
          <ion-row>
            <ion-col role="listitem" *ngFor="let image of item?.images" size-xs="12" size-md="12" size-lg="6">
              <div class="main-image-container" role="listitem">
                <ion-skeleton-text class="main-image" [ngClass]="{'hidden': item?.mainloaded}" [animated]="true"></ion-skeleton-text>
                <ion-img alt="{{item?.imageAlt}}" class="main-image" src="{{'assets/images/' + image}}" (ionImgDidLoad)="item.mainloaded = true"></ion-img>
              </div>
              <div role="listitem">
                <div class="button-container">
                  <ion-button style="--padding-start: 6vw; --padding-end: 6vw" class="print" size="large" fill="solid" color="medium" (click)="print(item.image)">
                    <ion-icon slot="start" src="{{'assets/icons-custom/printer.svg'}}" aria-hidden="true"></ion-icon>
                    PRINT
                  </ion-button>

                </div>
                <share-buttons [webpageCDN]="webpage?.cdn" [item]="item"></share-buttons>
              </div>
            </ion-col>
          </ion-row>

          <div role="listitem" class="description" [attr.aria-label]="'Item description'">{{item?.description}}</div>

          <related-items role="listitem" [relatedItems]="relatedItems" ></related-items>

          <app-footer *ngIf="item"></app-footer>

        </ion-list>

        <searchbar-items-list [searchbarItems]="searchbarItems" [searchbarInputLength]="searchbarInputLength"></searchbar-items-list>

    </ion-col>
    <ion-col class="ads" size="2"></ion-col>
  </ion-row>
</ion-content>

<!-- <ion-button style="--padding-start: 6vw; --padding-end: 6vw" size="large" fill="solid" color="warning" (click)="color(item?.images[3])">
  <ion-icon slot="start" src="{{'assets/icons-custom/color-palette-outline.svg'}}" aria-hidden="true"></ion-icon>
  COLOR
</ion-button>-->
