import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from '../pages/home/home.page';
import { ItemPage } from '../pages/item/item.page';
import { ContactPage } from '../pages/contact/contact.page';
import { PrivacyPolicyPage } from '../pages/privacy-policy/privacy-policy.page';

const routes: Routes = [
  { path: 'contact-us', component: ContactPage },
  { path: 'privacy-policy', component: PrivacyPolicyPage },
  { path: 'en/coloring-pages/:itemSlug', component: ItemPage },
  { path: '', component: HomePage },
  { path: '**', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
