import { Component, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, RouteReuseStrategy} from '@angular/router';
import { Subscription } from 'rxjs';
import { Platform, ModalController } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { PreloadService } from '../../services/preload.service';
import { isPlatformBrowser } from '@angular/common';
import { PreviewImageModal } from 'src/components/preview-image/preview-image.modal';

@Component({
  selector: 'app-item',
  templateUrl: './item.page.html',
  styleUrls: ['./item.page.scss'],
})
export class ItemPage {

  searchbarResults$: Subscription | undefined;
  item: any;
  category: any;
  searchbarItems: any[] = [];
  searchbarInputLength = 0;
  isBrowser: any;
  printJS: any;
  relatedItems: any;
  webpage: any;
  locale = 'en';

  constructor(
    private route: ActivatedRoute,
    private routeReuseStrategy: RouteReuseStrategy,
    @Inject(PLATFORM_ID) platformId: Object,
    private webpageService: WebpageService,
    private preloadService: PreloadService,
    public platform: Platform,
    private modalController: ModalController,
    public renderer: Renderer2) {

      this.routeReuseStrategy.shouldReuseRoute = () => false;

      this.getItemData();

      this.isBrowser = isPlatformBrowser(platformId);
  }

  async getItemData() {
    const itemSlug = this.route.snapshot.paramMap.get('itemSlug');
    this.item = await this.webpageService.getItem(itemSlug, `${this.locale}-items`);
    this.webpage = await this.webpageService.getWebpageData(this.locale);
    this.relatedItems = this.item.relatedItems;
    this.relatedItems.forEach((item:any) => {
      item.img1x = this.webpageService.transformFilname(item.img);
    });
    let urls:any = [];
    this.item.images.forEach((img:any) => {
      const url:any = `/assets/images/${img}`;
      urls.push(url);
    });
    this.preloadService.preloadImages(urls);

    this.webpageService.setMetaTags(this.item);
    this.webpageService.initializeSearchbar(this.locale);
    this.webpageService.clearSearchbarInput();
  }

  ionViewDidEnter() {
    this.webpageService.clearSearchbarInput();
    this.searchbarResults$ = this.webpageService.getSearchbarResults().subscribe(results => {
      this.searchbarItems = results.items;
      this.searchbarItems.forEach((searchbarItem:any) => {
        searchbarItem.img1x = this.webpageService.transformFilname(searchbarItem.i);
      });
      this.searchbarInputLength = results.searchbarInputLength;
    });
  }

  ionViewWillLeave() {
    if (this.searchbarInputLength > 0 ) { this.webpageService.triggerClear(); }
    this.searchbarResults$?.unsubscribe();
  }

  async color(img:any) {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      component: PreviewImageModal,
      cssClass: 'coloring-modal',
      componentProps: {
        image: img
      }
    });
    await modal.present();
  }

  async openSearchbarItem(item: any) { await this.webpageService.openSearchbarItem(item, this.locale); }

  print(image:any) {
    if (this.isBrowser) {
      import("print-js").then((data) => {
        this.printJS = data.default;
        const printable = `/assets/images/${image}`; // ${this.webpage.cdn}
        if (this.isBrowser) {
          this.printJS({
            printable,
            type: 'image',
            style: 'width:100%;position: absolute;top: 50%; transform: translateY(-50%)'
           })
        }
      });
    }
  }

}
