import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Platform, IonAccordionGroup } from '@ionic/angular';
import { WebpageService } from '../../services/web.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { TextService } from '../../services/text.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
  animations: [ ]
})
export class TopHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('accordionGroup', { static: true }) accordionGroup!: IonAccordionGroup;
  rootCategories: any[] = [];
  searchbarInput: any = '';
  clearSearchText$: any;
  rootCategories$: any;
  locale: any;
  isMobile = true;
  menuOpen = false;
  searchOpen = false;
  mobileMenuCategories:any;
  locale$: any;
  text:any = {};
  routes: any = {};
  homePath = '/';

  constructor(
    private platform: Platform,
    public textService: TextService,
    private router: Router,
    public webpageService: WebpageService) {

      this.translateTexts();
    }

  async ngOnInit() {
    this.clearSearchText$ = this.webpageService.clearSearchSubject.subscribe((clear:any) => {
      if (clear) {
        this.searchbarInput = '';
        this.webpageService.clearCompleted();
      }
    });
  }

  clearSearchbar() {
    //if (!this.isDesktop) {
    // npm install @capacitor/keyboard
    // npx cap sync
    // this.keyboard.hide();
   // }
  }

  searchItem(event: any) {
    this.searchbarInput = event.detail.value;
    this.webpageService.search(this.searchbarInput);
  }

  ngOnDestroy() { this.clearSearchText$.unsubscribe(); }

  toggleMenu() { this.menuOpen = !this.menuOpen; }

  toggleSearch() { this.webpageService.showSearchBar = !this.webpageService.showSearchBar; }

  translateTexts() {
    this.text.websiteName = this.textService.getText('websiteName', 'topheader');
    this.text.placeholderText = this.textService.getText('placeholderText', 'topheader');
    this.text.arialabel = this.textService.getText('arialabel', 'topheader');
    this.text.ariaButtonSearch = this.textService.getText('ariaButtonSearch', 'topheader');
    this.text.ariaButtonCloseSearch = this.textService.getText('ariaButtonCloseSearch', 'topheader');
    this.text.ariaButtonCloseMenu = this.textService.getText('ariaButtonCloseMenu', 'topheader');
    this.text.ariaButtonMenu = this.textService.getText('ariaButtonMenu', 'topheader');
    this.text.privacyPolicy = this.textService.getText('privacyPolicy', 'footer');
    this.text.contact = this.textService.getText('contact', 'footer');
    this.routes.contact = this.textService.getRoute('contact');
    this.routes.privacyPolicy = this.textService.getRoute('privacyPolicy');
  }

  collapseAccordionMenu() {
    this.accordionGroup.value = undefined;
  }

}
